<template>
    <div class="freight-detail">
        <div class="freight-detail-item">
            <div class="freight-title">
                <span>{{freight.title}}</span>
            </div>
            <div class="btn-back">
                <el-button class="backBtn" type="primary" @click="goBack">返回</el-button>
            </div>
        </div>
        <el-table :data="freight.freightList" style="width: 100%; flex: 1" size="medium"
                  :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#F6F6F6',fontSize: '16px',height:'60px'}"
                  :cell-style="{fontSize: '16px',color: '#333',height: '70px'}">
            <el-table-column label="运送方式" prop="delivery_mode"></el-table-column>
            <el-table-column label="运送到（国内）" align="center" width="320">
                <template slot-scope="scope">
                    <span>{{scope.row.city?scope.row.city:'为划分的区域将归于该模板下的默认运费'}}</span>
                </template>
            </el-table-column>
            <el-table-column label="首件（个）" align="center">
                <template slot-scope="scope">
                    <span>
                          {{scope.row.city?scope.row.first_thing:freight.defaultNum}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="首费（元）" align="center">
                <template slot-scope="scope">
                    <span>
                        {{scope.row.city?scope.row.first_cost:freight.defaultPrice}}
                    </span>
                </template>
            </el-table-column>
             <el-table-column label="续件（个）" align="center">
               <template slot-scope="scope">
                    <span>
                        {{scope.row.city?scope.row.add_thing:freight.addNum}}
                    </span>
        </template>
        </el-table-column>
                   <el-table-column label="续费（元）" align="center">
                        <template slot-scope="scope">
                    <span>
                        {{scope.row.city?scope.row.add_cost:freight.addPrice}}
                    </span>
        </template>
                    </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import {shopManagementDetail,teacheropTrainData} from '@/utils/apis'
    export default {
        name: "FreightDetail",
        data(){
            return{
                examId:Number(this.$route.query.examId) || null,
                studentId:Number(this.$route.query.studentId) || null,
                train_id:Number(this.$route.query.train_id) || null,
                // 运费单模板
                freight: {
                    title: '',
                    defaultPrice: 0,
                    defaultNum: 0,
                    addNum: 0,
                    addPrice: 0,
                    exemptPostage: 0,
                    freightList: [],
                },
                freightTemplateTitle:'',//运送模板
            }
        },
        mounted() {
            this.getShopManagementDetail()
        },
        methods:{
            //获取网店详情
            getShopManagementDetail(){
                let params = {}
                if (this.$route.query.train_id) {
                    params.train_id = this.train_id;
                } else {
                    params.exam_id = this.examId;
                    params.student_id = this.studentId;
                }
                let url = '';
                if (this.$route.query.train_id) {
                    url = teacheropTrainData;

                } else {
                    url = shopManagementDetail;
                }
                url(params).then((res) => {
                    if (this.$route.query.type === '1'){
                        let answer = res.data.freight_info.answer
                        if (answer){
                            this.freight.title = answer.freight_template_title;
                            this.freight.defaultPrice = answer.default_price;
                            this.freight.defaultNum = answer.default_num;
                            this.freight.addPrice = answer.add_price;
                            this.freight.addNum = answer.add_num;
                            this.freight.freightList = answer.freight_setting;
                            this.freight.exemptPostage = answer.is_exempt_postage
                        }
                        if (answer.is_exempt_postage === 1) {
                            //商家包邮
                            this.freight.freightList = [{
                                add_cost: answer.default_price,
                                add_thing: answer.default_num,
                                city: "全国",
                                delivery_mode: "快递",
                                first_cost: answer.default_price,
                                first_thing: answer.default_num,
                            }]
                        } else{
                            if (answer.freight_setting.length === 0) {
                                this.freight.freightList = [{
                                    add_cost: answer.default_price,
                                    add_thing: answer.default_num,
                                    city: answer.city,
                                    delivery_mode: answer.delivery_mode,
                                    first_cost: answer.default_price,
                                    first_thing: answer.default_num,
                                }]
                            }
                        }
                    }else{
                        let stu_answer = res.data.freight_info.stu_answer
                        if (stu_answer){
                            this.freight.title = stu_answer.freight_template_title;
                            this.freight.defaultPrice = stu_answer.default_price;
                            this.freight.defaultNum = stu_answer.default_num;
                            this.freight.addPrice = stu_answer.add_price;
                            this.freight.addNum = stu_answer.add_num;
                            this.freight.freightList = stu_answer.freight_setting;
                            this.freight.exemptPostage = stu_answer.is_exempt_postage
                        }
                        if (stu_answer.is_exempt_postage === 1) {
                            //商家包邮
                            this.freight.freightList = [{
                                add_cost: stu_answer.default_price,
                                add_thing: stu_answer.default_num,
                                city: "全国",
                                delivery_mode: "快递",
                                first_cost: stu_answer.default_price,
                                first_thing: stu_answer.default_num,
                            }]
                        } else {
                            if (stu_answer.freight_setting.length === 0) {
                                this.freight.freightList = [{
                                    add_cost: stu_answer.default_price,
                                    add_thing: stu_answer.default_num,
                                    city: "全国",
                                    delivery_mode: "快递",
                                    first_cost: stu_answer.default_price,
                                    first_thing: stu_answer.default_num,
                                }]
                            }
                        }
                    }
                }).catch((err) => {
                    console.error('err', err);
                });
            },
            //返回
            goBack(){
                this.$router.go(-1);
            }
        },
    }
</script>

<style scoped lang="scss">
    .freight-detail{
        padding: 0 20px;
        .freight-title {
            font-size: 20px;
            padding-left: 15px;
            line-height: 1;
            position: relative;
            display: flex;
            align-items: center;
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 5px;
                height: 20px;
                background: #2DC079;
                border-radius: 3px;
                transform: translateY(-50%);
            }
        }
        .freight-detail-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            .btn-back{
                margin-top: -5px;
            }
        }
    }
</style>